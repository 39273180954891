import { Outlet, useParams } from 'react-router-dom'
import Header from '../components/Header'

const Shop = () => {

    const { shopLinkName, staffType } = useParams()

    return (
        <>
            <Header title={shopLinkName.replace('-', ' ')} prevPage='/magazine' />
            <Outlet context={[shopLinkName, staffType]} />
        </>
    )
}

export default Shop