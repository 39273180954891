import { useState, useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"

import Index from "./pages/Index"
import Shops from "./pages/Shops"
import Shop from "./pages/Shop"
import Todos from "./pages/Todos"

import StaffCard from "./components/StaffCard"

const routes = [
  {
    pathName: '/',
    element: <Index />,
    id: 'Index'
  },
  {
    pathName: '/de-facut',
    element: <Todos />,
    id: 'Todos'
  },
  {
    pathName: '/magazine',
    element: <Shops />,
    id: 'Shops'
  },
  {
    pathName: ':shopLinkName',
    element: <Shop />,
    id: 'Shop',
    child: 
      {
        pathName: ':staffType',
        element: <StaffCard />,
        id: 'StaffType'
      }
  }
]

const App = () => {

  const location = useLocation()

  const [displayLocation, setDisplayLocation] = useState(location)
  const [transitionStage, setTransitionStage] = useState("fadeIn")

  useEffect(() => {
    if (location !== displayLocation) setTransitionStage("fadeOut")
  }, [location, displayLocation])

  const handleAnimationEnd = () => {
    if (transitionStage === 'fadeOut') {
      setTransitionStage('fadeIn')
      setDisplayLocation(location)
    }
  }

  return (
    <div data-location={displayLocation.pathname} className={`${transitionStage}`} onAnimationEnd={handleAnimationEnd}>
      <Routes location={displayLocation}>
      {
        routes.map((route, index) => (
          <Route key={index} path={route.pathName} element={route.element}>
            {route.child && (
              <Route path={route.child.pathName} element={route.child.element} />
            )}
          </Route>
        ))
      }
      </Routes>
    </div>
  )
}

export default App