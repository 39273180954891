import { Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'

import '../assets/css/Header.css'

const Header = (props) => {

    const { title, prevPage } = props

    return (
        <header className='header'>
            <Link to={prevPage} className='go-back-btn'>
                <FaChevronLeft />
            </Link>
            <h1 className='title'>
                {title}
            </h1>
            <Link to='/' className='options-btn'>
                <FaChevronLeft />
            </Link>
        </header>
    )
}

export default Header