import { useEffect, useState } from "react"
import { Link, useOutletContext } from "react-router-dom"
import { FaPhone, FaWhatsapp, FaEnvelope, FaChevronRight, FaCrown } from "react-icons/fa"
import getFetch from '../hooks/getFetch'
import PresenceRegister from "../hooks/PresenceRegister"
import '../assets/css/Nav.css'
import "../assets/css/StaffCard.css"

const StaffCard = () => {
    const [shopLinkName, staffType] = useOutletContext()
    const [isLoading, setIsLoading] = useState(true)
    const [staff, setStaff] = useState()
    const [activeStaff, setActiveStaff] = useState('mercantizori')

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            await getFetch('get_staff.php', {shop_link_name:shopLinkName, staff_type:staffType})
            .then(response => setStaff(response))
            setIsLoading(false)
        }
        fetchData()
    }, [shopLinkName, staffType])

    const teamLeader = (teamLeader) => {
        return parseInt(teamLeader) === 1 ? <FaCrown /> : ''
    }
    const sType = (type) => {
        return parseInt(type) === 0 ? <span className='multibrand'>MultiBrand</span> : <span className='dedicat'>Dedicat</span>
    }
    const sShift = (shift) => {
        return parseInt(shift) === 0 ? <span className='shift-1'>Tura 1</span> : <span className='shift-2'>Tura 2</span>
    }
    const partners = (partners = []) => {
        return partners.map((p, index) => <div key={`${p}-${index}`} className={`partner ${p}-${index}`}>{p}</div>)
    }

    return (
        <>
            {!isLoading && (
                <>
                    <nav className='navigation'>
                        {staff.staffCount.mercantizori > 0 && (
                            <Link to={`/${shopLinkName}/mercantizori`} className={(activeStaff === 'mercantizori') ? 'btn active' : 'btn'} onClick={() => setActiveStaff('mercantizori')}>Mercantizori</Link>
                        )}
                        {staff.staffCount['sefi-raioane'] > 0 && (
                            <Link to={`/${shopLinkName}/sefi-raioane`} className={(activeStaff === 'sefi-raioane') ? 'btn active' : 'btn'} onClick={() => setActiveStaff('sefi-raioane')}>Sefi raioane</Link>
                        )}
                        {staff.staffCount.agenti > 0 && (
                            <Link to={`/${shopLinkName}/agenti`} className={(activeStaff === 'agenti') ? 'btn active' : 'btn'} onClick={() => setActiveStaff('agenti')}>Agenti</Link>
                        )}
                    </nav>
                    <div className="cards staff-cards">
                        {staff.data.map(s => (
                            <div key={s.id} className='staff-card'>
                                <div className='card-header'>
                                    <h2 className='card-title'>{s.name} {teamLeader(s.team_leader)}</h2>
                                    {staffType !== 'mercantizori' && (
                                        <div className="card-subtitle"></div>
                                    )}
                                    {staffType === 'mercantizori' && (
                                        <div className='card-subtitle'>{sType(s.type)}@{sShift(s.shift)}</div>
                                    )}
                                </div>
                                <div className='card-body'>
                                    {staffType === 'mercantizori' && (<>
                                        <div className='card-projects'>
                                            <div className='card-group'>
                                                {partners(s.partners)}
                                            </div>
                                        </div>
                                        <PresenceRegister id={s.id} />
                                    </>)}
                                    <div className='card-group card-contact'>
                                        {s.phone !== '' & s.phone.length === 10 && (
                                            <>
                                                <a href={`tel:+4${s.phone}`}><FaPhone className='phone-wapp' /></a>
                                                <a href={`https://wa.me/4${s.phone}`}><FaWhatsapp className='phone-wapp' /></a>
                                            </>
                                        )}
                                        {s.email && (<a href={`mailto:${s.email}`}><FaEnvelope className='email' /></a>)}
                                    </div>
                                </div>
                                <Link to={`/${s.link_name}`} className='details-cta' style={{pointerEvents:'none'}}>
                                    <FaChevronRight />
                                </Link>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    )
}

export default StaffCard