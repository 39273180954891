const getFetch = async(url, params = null) => {
    let queryString = '';
    if (params !== null) {
        queryString = Object.entries(params).map(param => {
            return `${param[0]}=${param[1]}`
        }).join('&')
    }

    return await fetch(`${process.env.REACT_APP_API_LINK}/${url}?${queryString}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        redirect: 'follow'
    })
    .then(res => res.json())
}

export default getFetch