import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'
import Header from '../components/Header'
import getFetch from '../hooks/getFetch'
import '../assets/css/Shops.css'

const Shops = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [shops, setShops] = useState()

    useEffect(() => {
        const fetchData = async () => {
            await getFetch('get_shops.php')
            .then(response => setShops(response))
            setIsLoading(false)
        }
        fetchData()
    }, [])

    return (
        <>
            <Header title='magazine' prevPage='/' />
            <main className='cards'>
            {!isLoading &&
                shops.map(s => {
                    return (
                        <div key={s.id} className='shop-card'>
                            <div className='card-header'>
                                <h2 className='card-title'>{s.name}</h2>
                            </div>
                            <Link to={`/${s.link_name}/mercantizori`}>
                                <FaChevronRight />
                            </Link>
                        </div>
                    )
                })
            }
            </main>
        </>
    )
}

export default Shops