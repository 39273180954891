import { Link } from 'react-router-dom'

import FMCGLogo from '../assets/img/FMCG-Logo-512x512.png'
import '../assets/css/Index.css'

const Index = () => {

    return (
        <div className='index-page'>
            <div className="logo">
                <img src={FMCGLogo} alt='FMCG App' width='128' height='128' />
            </div>
            
            <nav className='nav'>
                <Link to='/magazine'>Magazine</Link>
                <Link to='/de-facut' style={{pointerEvents:'none'}}>De facut</Link>
            </nav>
        </div>
    )
}

export default Index