import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import './assets/css/App.css'

import App from './App'

createRoot(document.getElementById('FMCG-App')).render(
    <Router>
        <App />
    </Router>
)