import { useState, useEffect } from "react"
import { FaCheck, FaTimes } from "react-icons/fa"

const PresenceRegister = (props) => {

    let { id } = props

    const monthsName = ['Ianuarie','Februarie','Martie','Aprilie','Mai','Iunie','Iulie','August','Septembrie','Octombrie','Noiembrie','Decembrie']
    const currentMonth = new Date().getMonth()

    const [isLoading, setIsLoading] = useState(true)
    const [checkedPM, setCheckedPM] = useState('shift-2')
    const [checkedCM, setCheckedCM] = useState('shift-2')

    useEffect(() => {
        fetch(`https://fmcg.depozit-anvelope-sh.ro/api/check_presence_register.php?merchandiser_id=${id}&current_month=${currentMonth}`, {})
        .then((res) => res.json())
        .then((response) => {

            if(response.presence_register !== 'required') {
                return
            }
            if (parseInt(response[currentMonth-1]) === 1) setCheckedPM('shift-1')
            if (parseInt(response[currentMonth]) === 1) setCheckedCM('shift-1')

            setIsLoading(false)
        })
        .catch((error) => console.log(error))
    })
    
    return (
        <>
            {!isLoading && (
                <div className='card-condici'>
                    <div className={checkedPM}>{checkedPM === 'shift-1' ? <FaCheck /> : <FaTimes />} {monthsName[currentMonth-1]}</div>
                    <div className={checkedCM}>{checkedCM === 'shift-1' ? <FaCheck /> : <FaTimes />} {monthsName[currentMonth]}</div>
                </div>
            )}
        </>
    )
}

export default PresenceRegister